@import '../../../../scss/theme-bootstrap';

.footer-language-toggle {
  > span {
    &:after {
      position: relative;
      content: '|';
      top: -1px;
      padding: 0 15px;
      color: $color-black;
    }
    &:last-child:after {
      content: none;
    }
  }
  &__link {
    text-decoration: none;
  }
  &__text {
    text-decoration: underline;
  }
  a {
    color: $color-black;
  }
}
